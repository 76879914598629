export default {
  meta: {
    titles: {
      home: 'Accueil',
      sign: 'Procédure de signature'
    }
  },
  global: {
    title: 'Signature · Léonard de Vinci'
  },
  errors: {
    ERR_INVALID_TOKEN: {
      title: 'Lien invalide',
      message: 'Le lien de signature que vous avez utilisé est invalide'
    },
    ERR_INVALID_EXPDATE: {
      title: 'Lien invalide',
      message: 'Le lien de signature que vous avez utilisé est invalide'
    },
    ERR_EXPIRED: {
      title: 'Lien expiré',
      message: 'Le lien de signature que vous avez utilisé est expiré'
    },
    ERR_NOT_FOUND: {
      title: 'Signature introuvable',
      message: 'La procédure de signature est inconnue ou introuvable'
    },
    ERR_PROVIDER_UNAVAILABLE: {
      title: 'Signature temporairement indisponible',
      message: 'Le service de signature électronique est temporairement indisponible. Veuillez réessayer plus tard.'
    },
    ERR_SIGNED: {
      title: 'Procédure de signature validée',
      message: 'Votre signature a bien été prise en compte'
    },
    ERR_DECLINED: {
      title: 'Procédure de signature refusée',
      message: 'Vous avez refusé la signature de ce document'
    },
    ERR_CANCELED: {
      title: 'Signature annulée',
      message: "Cette procédure de signature n'est plus active, si vous avez accédé à cette signature par e-mail, merci de vérifier si un nouveau lien de signature vous a été transmis"
    }
  }
}
