import { App } from 'vue'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import { Mutations } from '@/store/enums/StoreEnums'

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    public static vueInstance: App;

    /**
     * @description Init. Service API
     */
    public static init (app: App<Element>) {
      ApiService.vueInstance = app
      ApiService.vueInstance.use(VueAxios, axios)
      ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL
      ApiService.vueInstance.axios.interceptors.response.use(res => res, err => {
        return Promise.reject(err)
      })
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader (): void {
      ApiService.vueInstance.axios.defaults.headers.common.Accept =
            'application/json'
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query (
      resource: string,
      params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.get(resource, params)
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get<T> (
      resource: string,
      slug = ''
    ): Promise<AxiosResponse<T>> {
      return ApiService.vueInstance.axios.get<T>(slug ? `${resource}/${slug}` : resource)
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post (
      resource: string,
      params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.post(resource, params)
    }

  // /**
  //  * @description send the UPDATE HTTP request
  //  * @param resource: string
  //  * @param slug: string
  //  * @param params: AxiosRequestConfig
  //  * @returns Promise<AxiosResponse>
  //  */
  // public static update(
  //     resource: string,
  //     slug: string,
  //     params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //     return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  // }

  // /**
  //  * @description Send the PUT HTTP request
  //  * @param resource: string
  //  * @param params: AxiosRequestConfig
  //  * @returns Promise<AxiosResponse>
  //  */
  // public static put(
  //     resource: string,
  //     params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //     return ApiService.vueInstance.axios.put(`${resource}`, params);
  // }

  // /**
  //  * @description Send the DELETE HTTP request
  //  * @param resource: string
  //  * @returns Promise<AxiosResponse>
  //  */
  // public static delete(resource: string): Promise<AxiosResponse> {
  //     return ApiService.vueInstance.axios.delete(resource);
  // }
}

export default ApiService
